/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { SITE_URL } from '../apis/baseURL';
import Button from '../components/Button';
import Icon from '../components/Icon';
import { MainLayout } from '../components/Layout';
import { Container } from '../components/LayoutComp';
import SEO from '../components/SEO';
import withI18next from '../components/withI18next';
import culture from '../content/culture-code';
import { isBrowser } from '../utils';
import getParamFromQueryString from '../utils/getParamFromQueryString';

function CultureCode({ location }) {
  const testId = getParamFromQueryString(location.search, 'testId');
  const type = getParamFromQueryString(location.search, 'type');
  const [argree, setArgree] = useState(false);

  return (
    <MainLayout hideReview hideSideCTA>
      <SEO
        title="Culture code"
        description="Giúp học viên hiểu được trình độ hiện tại của bản thân, từ đó chọn được khoá học phù hợp nhất với trình độ của mình."
      />
      <Container>
        <IntroWrapper>
          <Intro data={culture.intro} />
        </IntroWrapper>
        <Blocks data={culture.blocks} />
        <DetailLink>
          (Chi tiết Linearthinking:{' '}
          <a href={`${SITE_URL}/gioi-thieu-linearthinking`}>
            {SITE_URL}/gioi-thieu-linearthinking
          </a>
          ).
        </DetailLink>
        <ConclusionBlock>{culture.note}</ConclusionBlock>
      </Container>

      <div
        style={{
          backgroundColor: '#F5F5F5',
          paddingTop: '1px',
          paddingBottom: '1px'
        }}
      >
        <Container>
          <NoteWrapper>
            <TxtBlock>
              NHƯNG, thậm chí việc học ở DOL có hiệu quả gấp 3. Các bạn ở ngoài
              mất 12 tháng để từ 5.5 lên 7.5 (ngày 6 tiếng),{' '}
              <RedText> thì các bạn vẫn mất 4 tháng. </RedText>
            </TxtBlock>
            <TxtBlock>{culture.desTwo}</TxtBlock>
            <TxtBlock>
              <RedText>{`>>> CÁC BẠN VẪN PHẢI HỌC`}</RedText> và học rất nghiêm
              túc, chẳng qua việc học của các bạn được cá nhân hoá và rút ngắn
              hơn thôi.
            </TxtBlock>
            <TxtBlockBold>{culture.desThree}</TxtBlockBold>
            <TxtBlock>{culture.desFour}</TxtBlock>
            <TxtBlockBold>{culture.conclusion}</TxtBlockBold>
          </NoteWrapper>
        </Container>
      </div>

      <Container>
        <ArgreementWrapper>
          <Argreement>
            {argree ? (
              <ChbElement
                type="checkbox"
                checked
                onChange={() => setArgree(!argree)}
              />
            ) : (
              <ChbElement type="checkbox" onChange={() => setArgree(!argree)} />
            )}
            <span role="button" onClick={() => setArgree(!argree)} tabIndex="0">
              Mình đồng ý với Văn hoá lớp học của DOL
            </span>
          </Argreement>
          {isBrowser() && (
            <ActionWrapper>
              <Button
                to={`/${type}?testId=${testId}`}
                disabled={!argree}
                style={{ paddingLeft: '3em', paddingRight: '3em' }}
              >
                Làm bài kiểm tra
              </Button>
            </ActionWrapper>
          )}
        </ArgreementWrapper>
      </Container>
    </MainLayout>
  );
}

CultureCode.propTypes = {
  location: PropTypes.shape({}).isRequired
};

export default withI18next()(CultureCode);

const Intro = ({ data }) => (
  <>
    <PageTitle>{data.pageTitle}</PageTitle>
    <PageHeading>{data.pageHeading}</PageHeading>
    <PageDescription>{data.pageDescription}</PageDescription>
    <RedBg>
      <span>{data.redTitle}</span>
    </RedBg>
  </>
);

Intro.propTypes = {
  data: PropTypes.shape({}).isRequired
};

const ArgreementWrapper = styled.div`
  padding: 1.5em;
  text-align: center;

  @media screen and (min-width: 1024px) {
    padding: 3em 0;
  }
`;
const Argreement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > span {
    user-select: none;
    outline: none;
  }
`;
const ChbElement = styled.input`
  background: #ffffff;
  border: 1px solid #aaaaaa;
  box-sizing: border-box;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;
const ActionWrapper = styled.div`
  text-align: center;
  margin-top: 1em;
  margin-bottom: 2em;
`;
const IntroWrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
`;
const PageTitle = styled.h1`
  font-size: 28px;
  text-align: center;
  font-weight: 700;
  color: ${({ theme }) => theme.brand};
  @media screen and (min-width: 1024px) {
    font-size: 42px;
  }
`;
const PageHeading = styled.h4`
  font-size: 20px;
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 1em;

  @media screen and (min-width: 1024px) {
    font-size: 24px;
  }
`;
const PageDescription = styled.p`
  @media screen and (min-width: 1024px) {
    font-size: 16px;
    line-height: 26px;
  }
`;
const RedBg = styled.p`
  margin: 40px 0;
  text-align: center;
  span {
    background-color: ${({ theme }) => theme.brand};
    color: #fff;
    display: inline-block;
    margin: 0 auto;
    padding: 10px 20px;
    @media screen and (min-width: 1024px) {
      font-size: 24px;
      padding: 7px 20px;
    }
  }
`;
const ConclusionBlock = styled.div`
  margin: 2em 0;
`;

const Blocks = ({ data }) => (
  <BlockWrapper>
    {data.map(dt => (
      <div className="blockItemWrp">
        <BlockItem>
          <div className="itemBody">
            <CultureIcon type={dt.icon} />
            <BlockTitle>{dt.title}</BlockTitle>
            <BlockDes>{dt.description}</BlockDes>
          </div>
          <Footer>
            <div dangerouslySetInnerHTML={{ __html: dt.footer }} />
          </Footer>
        </BlockItem>
      </div>
    ))}
  </BlockWrapper>
);
Blocks.propTypes = {
  data: PropTypes.shape({}).isRequired
};
const BlockWrapper = styled.div`
  margin-left: -20px;
  margin-right: -20px;

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;

    .blockItemWrp {
      width: 33.33%;
    }
  }
`;
const BlockItem = styled.div`
  border-radius: 6px;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 2em;
  position: relative;

  .itemBody {
    margin-top: 1.5em;
  }

  @media screen and (min-width: 1024px) {
    .itemBody {
      min-height: 420px;
      margin-top: 1.5em;
    }
  }
`;
const BlockTitle = styled.p`
  font-size: 20px;
  line-height: 27px;
  margin: 1em;
  font-weight: 700;
  text-align: center;

  @media screen and (min-width: 1024px) {
    font-size: 24px;
  }
`;
const BlockDes = styled.p`
  margin: 1.5em;
  text-align: center;
`;
const Footer = styled.div`
  background-color: #2d3942;
  color: #fff;
  padding: 1rem;
  min-height: 40px;
  width: 100%;
  text-align: center;
  border-radius: 0 0 6px 6px;
  font-size: 16px;
  line-height: 1.2;

  @media screen and (min-width: 1024px) {
    font-size: 24px;
    position: absolute;
    bottom: 0;
  }
`;
const DetailLink = styled.div`
  margin: 2em 0;
  text-align: center;
  font-size: 14px;

  a {
    text-decoration: none;
    color: #000;
  }

  @media screen and (min-width: 1024px) {
    font-size: 16px;
  }
`;
const TxtBlock = styled.div`
  padding: 0;
`;
const RedText = styled.span`
  color: ${({ theme }) => theme.brand};
`;
const NoteWrapper = styled.div`
  margin: 2em 0;
`;
const TxtBlockBold = styled.div`
  margin: 2em 0;
  font-weight: 700;
`;
const CultureIcon = styled(Icon)`
  text-align: center;
  display: block;
`;
